<template>
  <div class="useRegistration">
    <van-nav-bar title="设备使用登记" left-text="返回" @click-left="back()" left-arrow style="z-index:1000">
      <van-icon name="scan" slot="right" class="tabIcon" @click="scanClick()" />
    </van-nav-bar>
    <div class="useRegistration-content">
      <van-cell-group>
        <van-field v-model="equipmentForm.code" label="设备编号" placeholder="请扫码" readonly clearable :label-width="100" />
        <van-field v-model="equipmentForm.code" label="设备名称" placeholder="请扫码" readonly clearable :label-width="100" />
        <van-field readonly v-model="equipmentForm.projectName" clickable type="textarea" autosize :label="'选择项目'"
          :placeholder="'请选择项目'" @click="showPickerUserddp = true" />
        <van-field v-model="equipmentForm.time" label="开始使用时间" placeholder="请选择使用时间" @click="endDateShow = true"
          readonly clickable></van-field>
        <van-field v-model="durationOfUse" label="已使用时长(小时)" placeholder="请扫码" readonly clearable :label-width="120"
          v-if="status == 1" />
      </van-cell-group>
      <!-- 选择时间 -->
      <van-popup v-model="endDateShow" position="bottom">
        <van-datetime-picker type="datetime" title="选择截止时间" v-model="currentDate2" @confirm="endDateChange"
          @cancel="endDateShow = false; $forceUpdate();" />
      </van-popup>
      <!-- 选择项目 -->
      <van-popup v-model="showPickerUserddp" position="bottom" style="height: 84%">
        <div class="popupDiv">
          <div class="popupSearch">
            <van-search v-model="userName" shape="round" background="#F4F4F4" placeholder="请输入项目名称/编号" @clear="sea()"
              @blur="sea()" @search="sea()" @input="sea()" />
          </div>

          <div class="popupCon">
            <div class="popupTitle">近期选择项目</div>

            <div v-for="(item, index) in integrationProjectList" :key="index" class="popupItem paddingDiv"
              @click="fZr(item, index)">
              <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
              <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
            </div>

            <div class="popupTitle marginTop">全部项目</div>

            <div v-for="(item, index) in projectss" :key="item.id" class="popupItem paddingDiv"
              @click="fZr(item, index)">
              <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
              <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
            </div>

            <!-- <div class="popupTitle paddingDiv">全部项目</div> -->
          </div>
        </div>
      </van-popup>
    </div>
    <div class="useRegistration-footer">
      <van-button type="primary" size="info" round block @click="operation" :disabled="!this.equipmentForm.code">{{
        status == 0 ? '确定开始使用' : '确定结束使用' }}</van-button>
    </div>
    <!-- 扫码 -->
    <div class="qrcodeClass" v-if="showQrcode">
      <Qrcode @closeQrcode="closeQrcode" @determineQrcode="determineQrcode" />
    </div>
  </div>
</template>

<script>
import Qrcode from '../../components/qrcode.vue'
export default {
  components: { Qrcode },
  data() {
    return {
      equipmentForm: {
        code: '',
        name: '',
        projectName: '',
        time: this.getToday()
      },
      endDateShow: false,
      currentDate2: this.getToday(),
      showPickerUserddp: false,
      userName: '',
      integrationProjectList: [],
      projectss: [],
      project: [],
      proads: [],
      showQrcode: false,
      status: 0, // 0: 新增，1：编辑
      durationOfUse: 0
    };
  },
  mounted() {
    this.reset()
    this.getRecentlyProject()
    this.getPeoject()
  },
  methods: {
    operation() {
      this.equipmentForm = {
        code: '',
        name: '',
        projectName: '',
        time: this.getToday()
      },
        this.status = 0
      this.$toast.success('操作成功')
    },
    closeQrcode() {
      this.showQrcode = false
    },
    scanClick() {
      this.showQrcode = true
    },
    determineQrcode(val) {
      console.log(val, '<=========== val')
      if (val != '') {
        const newVal = JSON.parse(val)
        this.equipmentForm = {
          code: newVal.equipmentNumber,
          name: newVal.equipmentName,
          projectName: newVal.projectName,
          time: newVal.usageTime
        }
        this.durationOfUse = newVal.durationOfUse || 0
        this.status = newVal.status || 0
      } else {
        this.$toast.fail('无效的二维码')
      }
      this.closeQrcode()
    },
    reset() {
      this.equipmentForm = {
        code: '',
        name: '',
        projectName: '',
        time: this.getToday()
      }
    },
    fZr(row, index) {
      this.equipmentForm.projectName = row.projectName
      this.showPickerUserddp = false
    },
    sea() {
      if (this.userName.length > 0) {
        let data = this.proads.filter(item => { return item.projectName.indexOf(this.userName.toUpperCase()) != '-1' });
        let dataList = this.proads.filter(item => { return item.projectCode.indexOf(this.userName.toUpperCase()) != '-1' });
        let dataTree = data.concat(dataList)
        let arrList = Array.from(new Set(dataTree))
        this.projectss = arrList
      } else {
        this.projectss = this.proads
      }
    },
    getToday() {
      return this.formatDate(new Date())
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，并确保两位数
      const day = String(date.getDate()).padStart(2, '0'); // 获取日期，并确保两位数
      const hours = String(date.getHours()).padStart(2, '0'); // 获取小时，并确保两位数
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 获取分钟，并确保两位数
      const seconds = String(date.getSeconds()).padStart(2, '0'); // 获取秒钟，并确保两位数

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    endDateChange(date) {
      this.equipmentForm.time = this.formatDate(date)
      this.endDateShow = false
    },
    back() {
      history.back();
    },
    getRecentlyProject() {
      this.$axios.post('/project/nearProject', {})
        .then(res => {
          if (res.code == 'ok') {
            this.integrationProjectList = res.data;
          }
        }).catch(err => { this.$toast.clear(); this.cardRefLoading = false; })
    },
    getPeoject() {
      this.$axios.post("/project/getProjectList", { forReport: 1 })
        .then(res => {
          if (res.code == "ok") {
            for (var i in res.data) {
              if (res.data[i].projectCode == 'null' || res.data[i].projectCode == null) {
                res.data[i].projectCode = ' '
              }
            }
            this.projectss = res.data;
            this.project = res.data;
            this.projectss = this.projectss.filter(p => p.status == 1 || p.status == 4);
            this.proads = res.data
          } else {
            this.$toast.fail('获取失败:' + res.msg);
          }
        }).catch(err => { this.$toast.clear(); });
    },
  },
};
</script>

<style lang="less" scoped>
.useRegistration {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;

  .useRegistration-content {
    flex: 1;
    overflow: auto;
  }

  .useRegistration-footer {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }

  .tabIcon {
    font-size: 20px;
    font-weight: bold;
  }

  .qrcodeClass {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 9999;
  }
}
</style>